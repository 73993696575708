/**
 *
 * ContentWrapper
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import Container from 'components/layout/Container/index.js';
import cx from 'utils/cx.js';

function ContentWrapper(props) {
  const { shaded = false, children, width, className, noWrapper } = props;
  if (noWrapper) {
    return children;
  }
  return (
    <div className={cx(className, 'padding', shaded && 'shaded')}>
      <Container width={width}>
        {children}
      </Container>
    </div>
  );
}

ContentWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  shaded: PropTypes.bool,
  noWrapper: PropTypes.bool,
  width: PropTypes.string,
  className: PropTypes.string,
};

export default ContentWrapper;
