/**
 *
 * StartEndPicker
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { object, date, bool } from 'yup';

import { Form } from 'react-bootstrap';
import DatePicker from 'components/interaction/DatePicker/index.jsx';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages.js';
import FormControlWrapper from '../Form4/FormControlWrapper.jsx';
import handleChange from './handleChange.js';

const Wrapper = styled(FormControlWrapper)`
  input {
    max-width: ${(props) => props.allday ? '9em' : '12em'};
  }
  .react-datepicker-wrapper {
    min-width: ${(props) => props.allday ? '9em' : '12em'};
    margin-bottom: 0.5rem;
  }
  @media (min-width: 576px) {
    .react-datepicker-wrapper {
      margin-bottom: 0;
    }
    .form-check {
      margin-top: 0.5rem;
    }
  }
`;

function StartEndPicker(props) { // eslint-disable-line react/prefer-stateless-function
  const {
    disabled, value = {}, withTime = true, onChange, onBlur, requireEnd, maxDate, future, className, id, ...rest
  } = props;
  const { allDay } = value;
  const intl = useIntl();
  const isAllDay = allDay || !withTime;

  const createHandler = (prop) => (val) => handleChange(prop, val, { value, onChange, withTime, requireEnd, maxDate });

  function renderInput(key) {
    const minDate = future ? new Date() : undefined;
    const options = key === 'start' ? {
      minDate,
      maxDate,
    } : {
      startDate: value.start,
      endDate: value.end,
      selectsEnd: true,
      minDate: value.start || minDate,
      maxDate,
      isClearable: !requireEnd,
    };

    const placeholder = `${intl.formatMessage(messages[key])}${isAllDay ? '' : intl.formatMessage(messages.time)}`;
    return (
      <DatePicker
        value={value[key]}
        onChange={createHandler(key)}
        placeholderText={placeholder}
        disabled={disabled}
        showTimeSelect={!isAllDay}
        {...rest}
        {...options}
      />
    );
  }

  const until = isAllDay ? messages.upto : messages.until;
  return (
    <Wrapper allday={Number(isAllDay || 0)} onBlur={onBlur} className={className} border={2}>
      <div className="d-flex align-items-baseline flex-wrap">
        {renderInput('start')}
        <span className="mx-3 text-nowrap"><FormattedMessage {...until} /></span>
        {renderInput('end')}
      </div>
      {withTime && (
        <Form.Check
          type="checkbox"
          checked={allDay}
          disabled={disabled}
          onChange={createHandler('allDay')}
          label={intl.formatMessage(messages.allDay)}
        />
      )}
    </Wrapper>
  );
}

StartEndPicker.propTypes = {
  value: PropTypes.object,
  id: PropTypes.string,
  maxDate: PropTypes.object,
  className: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  future: PropTypes.bool,
  disabled: PropTypes.bool,
  withTime: PropTypes.bool,
  requireEnd: PropTypes.bool,
};

StartEndPicker.getSchema = (options, intl) => {
  const result = object().shape({
    start: date().nullable().label(intl.formatMessage(messages.start)),
    end: date().nullable().label(intl.formatMessage(messages.end)),
    allDay: bool(),
  });
  const { attributes, required } = options;
  if (required) {
    result.fields.start = result.fields.start.required();
  }
  if (attributes?.future) {
    result.fields.start = result.fields.start.min(new Date());
  }
  if (attributes?.requireEnd) {
    result.fields.end = result.fields.end.required();
  }
  return result;
};

export default StartEndPicker;
