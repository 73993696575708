/**
 *
 * CropImage
 *
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ReactCrop from 'react-image-crop';
import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import 'react-image-crop/dist/ReactCrop.css';
import PrimaryAction from '../../PrimaryAction/index.jsx';
import messages from './messages.js';

const WrapModal = styled(Modal)`
  .ReactCrop__crop-selection {
    border-radius: ${(p) => p.radius};
  }
  .modal-body {
    text-align: center;
  }
  .ReactCrop__image {
    max-height: calc(100vh - 200px);
  }
`;

const CROP_TYPE_RATIO = {
  flex: 0,
  group: '20%',
  user: '50%',
};

function CropModal(props) {
  const { file, height = '800px', onHide, onChange, cropType } = props;

  const [crop, setCrop] = useState();
  const [image, setImage] = useState();

  const handleImageLoaded = (ev) => {
    const img = ev.target;
    setImage(img);
    const newCrop = {
      unit: 'px',
      x: 0,
      y: 0,
      width: img.width,
      height: img.height,
    };
    if (cropType !== 'flex') {
      const imgAspect = img.width / img.height;
      if (imgAspect > 1) {
        newCrop.width /= imgAspect;
        newCrop.x += (img.width - newCrop.width) / 2;
      } else {
        newCrop.height *= imgAspect;
        newCrop.y += (img.height - newCrop.height) / 2;
      }
    }
    setCrop(newCrop);
  };

  function handleComplete() {
    if (!image || !crop) {
      return;
    }
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const cropBorders = {
      top: scaleY * crop.y,
      right: scaleX * (image.width - crop.x - crop.width),
      bottom: scaleY * (image.height - crop.y - crop.height),
      left: scaleX * crop.x,
    };
    onChange(cropBorders);
  }

  return (
    <WrapModal show animation={false} height={height} radius={CROP_TYPE_RATIO[cropType]}>
      <Modal.Header>
        <Modal.Title><FormattedMessage {...messages.header} /></Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {file
          && (
            <ReactCrop
              crop={crop}
              aspect={cropType !== 'flex' ? 1 : undefined}
              keepSelection
              onChange={(c) => setCrop(c)}
              onImageLoaded={handleImageLoaded}
            >
              <img src={file.preview} alt="" onLoad={handleImageLoaded} />
            </ReactCrop>
          )}
      </Modal.Body>

      <Modal.Footer>
        <PrimaryAction onSubmit={handleComplete} submitText="save" onCancel={onHide} />
      </Modal.Footer>
    </WrapModal>
  );
}

CropModal.propTypes = {
  file: PropTypes.object,
  cropType: PropTypes.oneOf(['user', 'group', 'flex']),
  height: PropTypes.string,
  onChange: PropTypes.func,
  onHide: PropTypes.func,
};

export default CropModal;
