/**
*
* Select
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Form } from 'react-bootstrap';
import { requiredString } from 'components/interaction/Form4/useConvertSchema.js';
import Option from '../Option/index.jsx';
import messages from './messages.js';

function Select(props) {
  const {
    value, onChange, onBlur, onCancel, options, disabled, className = '', placeholder = messages.selectOne, ...rest
  } = props;
  const placeholderClass = placeholder && value === undefined ? 'input-placeholder' : '';
  const newClass = classNames([className, placeholderClass]);
  const handleChange = (ev) => {
    if (onBlur) {
      onBlur(ev);
    }
    const foundOption = options.find((option) => option.value?.toString() === ev.target.value);
    return onChange(foundOption?.value);
  };
  function handleEscape(ev) {
    if (ev.key === 'Escape') {
      onCancel();
    }
  }
  return (
    <Form.Select
      className={newClass}
      value={value}
      onChange={handleChange}
      onBlur={onBlur}
      disabled={disabled}
      onKeyDown={handleEscape}
      {...rest}
    >
      {placeholder && <Option value={0} key="-" label={placeholder} />}
      {options.map((option) => (
        <Option key={option.value === undefined ? '---' : option.value} {...option} />
      ))}
    </Form.Select>
  );
}

Select.propTypes = {
  value: PropTypes.any,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onCancel: PropTypes.func,
  options: PropTypes.array,
};

Select.getSchema = requiredString;

export default Select;
