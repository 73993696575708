/**
 *
 * Materials
 *
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import withApi2 from 'containers/Api2/withApi2.jsx';
import styled from 'styled-components';
import UserText from 'components/media/UserText/Loadable.js';
import { FormattedMessage } from 'react-intl';
import NumberInput from 'components/interaction/NumberInput/index.jsx';
import { SpinnerDiv } from 'components/Spinner/index.jsx';
import { idValues } from 'utils/arrays.js';
import { useApi2call } from 'containers/Api2/index.js';
import messages from './messages.js';
import MaterialsList from './MaterialsList/index.jsx';

const Wrapper = styled.div`
  .materials-view {
    border-top: 1px solid ${(p) => p.theme.tableBorderColor};
    border-bottom: 2px solid ${(p) => p.theme.tableBorderColor};
    padding: 10px 0;
  }
  width: 100%;
  position: relative;
  .btn-group {
    display: flex;
    justify-content: stretch;
    & > * {
      flex-grow: 1;
    }
  }
`;

const Scrollable = styled.div`
  @media screen {
    max-height: 640px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-right: -15px;
    padding-right: 15px
  }
`;

const FlexLi = styled.li`
  overflow-y: hidden;
  .mat-flex-col {
    max-width: calc(100% - 130px);
  }
  .user-text {
    max-width: 100%;
  }
  .still-needed {
    font-size: 0.8em;
  }
  .no-more-needed {
    opacity: 0.35;
  }
  .spinner-opaque > :not(:last-child) {
    opacity: 0.5;
  }
`;

function Materials(props) {
  const { event, shaded, withList, className } = props;

  const getMissing = (backing) => backing.needed - backing.reserved;
  const getMax = (backing) => getMissing(backing) + backing.mine;
  const api2call = useApi2call();
  function fetchBackers() {
    if (!withList) {
      return;
    }
    function parser(payload) {
      const { material, backers } = payload;
      return { ...material, backers };
    }
    function reducer(state, action) {
      const { payload } = action;
      return payload.list.reduce((acc, id) => ({
        ...acc,
        [id]: { ...state[id], ...payload[id] },
      }), state);
    }
    api2call({ url: `events/${event.id}/materials`, refresh: true, parser, reducer });
  }

  function handleChange(material) {
    return (diff) => {
      const reducer = (state, action) => ({
        ...state,
        ...action.payload,
      });
      const method = diff > 0 ? 'POST' : 'DELETE';
      api2call({
        method,
        url: `eventmaterials/${material.id}/backing`,
        selector: `events/${event.id}/materials/${material.id}`,
        reducer,
        withPromise: true,
        noReport: [409],
      })
        .then(() => fetchBackers());
    };
  }

  useEffect(fetchBackers, []);

  return (
    <>
      <Wrapper className={className}>
        <div className="mb-2">
          <FormattedMessage {...messages.ask} />
        </div>
        <Scrollable>
          <ul className="list-unstyled">
            {idValues(event.materials).map((mat) => (
              <FlexLi key={mat.id} shaded={shaded} className="text-start mb-3 d-flex justify-content-between">
                <div className="d-flex justify-content-start flex-column mat-flex-col">
                  <UserText inline>{mat.name}</UserText>
                  <span className="small">
                    <FormattedMessage {...messages.needed} values={{ count: getMissing(mat.backing), total: mat.backing.needed }} />
                  </span>
                </div>
                <SpinnerDiv show={mat.working} className={getMax(mat.backing) === 0 ? 'no-more-needed' : 'spinner-opaque'} color="#fff">
                  <NumberInput min={0} max={getMax(mat.backing)} value={mat.backing.mine} shaded={shaded} onStep={handleChange(mat)} />
                </SpinnerDiv>
              </FlexLi>
            ))}
          </ul>
          {withList && <MaterialsList materials={event.materials} />}
        </Scrollable>
      </Wrapper>
      {withList && (
        <div className="visible-print">
          <h5><FormattedMessage {...messages.backers} /></h5>
          <MaterialsList show materials={event.materials} />
        </div>
      )}
    </>
  );
}

Materials.propTypes = {
  className: PropTypes.string,
  shaded: PropTypes.bool,
  withList: PropTypes.bool,
  event: PropTypes.object,
  api2: PropTypes.func,
};

export default withApi2()(Materials);
