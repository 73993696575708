/**
 *
 * Rsvp
 *
 */

import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';
import { Button, ButtonGroup } from 'react-bootstrap';
import { withApi2, useApi2call } from 'containers/Api2/index.js';
import Icon from 'components/media/Icon/index.js';
import { FormattedMessage } from 'react-intl';
import { SpinnerDiv } from 'components/Spinner/index.jsx';
import RsvpList from './RsvpList/index.jsx';

import messages from './messages.js';
import { RSVP_REPLIES } from './constants.js';

const Wrapper = styled.div`
  width: 100%;

  .btn-group {
    display: flex;
    justify-content: stretch;
    position: static;
    & > .btn {
      position: static;
      &.active {
        z-index: auto;
      }
    }
    & > * {
      flex-grow: 1;
    }
    .inWidget.btn, .inWidget.btn:hover {
      &.active {
        &:first-child {
          color: ${(p) => p.theme.textSuccess};
        }
        &:nth-child(2) {
          color: ${(p) => p.theme.textWarning};
        }
        &:last-child {
          color: ${(p) => p.theme.textDanger};
        }
      }
    }
    .inModal.btn.active {
      box-shadow: none;
      &:first-child {
        background-color: ${(p) => p.theme.brandSuccess};
        color: ${(p) => p.theme.bodyBg};
      }
      &:nth-child(2) {
        background-color: ${(p) => p.theme.brandWarning};
        color: ${(p) => p.theme.bodyBg};
      }
      &:last-child {
        background-color: ${(p) => p.theme.brandDanger};
        color: ${(p) => p.theme.bodyBg};
      }
    }
  }
`;

const RsvpButton = styled(Button)`
  &.btn.btn-light {
    /* margin-left: 10px;
    padding: 2px 6px 4px; */

    border: transparent;
    color: ${(p) => p.theme.brandPrimary};
    &.inWidget {
      color: #fff;
      background-color: rgba(0,0,0, 0.25);
    }
  }
`;

function Rsvp(props) {
  const { className, count, event, onView, inWidget } = props;
  const [show, toggleShow] = useReducer((f) => !f, false);
  const api2call = useApi2call();

  function handleRsvp(reply) {
    const reducer = (state, action) => ({
      ...state,
      rsvpResult: action.options.body.rsvp,
      rsvpCount: state.rsvpCount + (state.rsvpResult ? 0 : 1),
      rsvpList: undefined,
    });
    return () => api2call({ method: 'POST', url: `events/${event.id}/rsvp`, selector: `events/${event.id}`, body: { rsvp: reply }, reducer });
  }

  const inWidgetClass = inWidget ? 'inWidget' : 'inModal';
  return (
    <Wrapper className={className}>
      <div className="rsvp-text">
        <FormattedMessage {...messages.attend} />
        {count && onView && (
          <Button variant="link" online onClick={onView}>
            <FormattedMessage {...messages.viewResponses} values={{ count }} />
          </Button>
        )}
      </div>
      <div className="d-flex align-items-baseline">
        <div className="flex-grow-1">
          <SpinnerDiv show={event.working} />
          <ButtonGroup>
            {RSVP_REPLIES.map((r) => (
              <Button key={r.id} active={event.rsvpResult === r.id} variant="light" className={inWidgetClass} onClick={handleRsvp(r.id)}>
                <FormattedMessage {...messages[r.key]} />
              </Button>
            ))}
          </ButtonGroup>
        </div>
        {event.rsvpCount > 0
            && (
              <RsvpButton className={classNames([inWidgetClass, 'ms-3'])} variant="light" onClick={toggleShow}>
                <Icon className="ss-user lower-1 me-1" />
                {event.rsvpCount}
              </RsvpButton>
            )}
      </div>
      {show && <RsvpList event={event} params={{ eventId: event.id }} onHide={toggleShow} />}
    </Wrapper>
  );
}

Rsvp.propTypes = {
  className: PropTypes.string,
  inWidget: PropTypes.bool,
  event: PropTypes.object,
  api2: PropTypes.func,
  count: PropTypes.number,
  onView: PropTypes.func,
};

export default withApi2()(Rsvp);
