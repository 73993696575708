/**
 * DeleteButton
 * Show disabledIcon instead of X if disabled
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import useDialog from 'containers/Dialog/useDialog.js';
import IconButton from 'components/interaction/IconButton/index.jsx';

function DeleteButton(props) {
  const {
    disabled, disabledIcon, className, variant = 'link', icon = 'ss-delete fs-3', onClick, confirmLabel, confirmText, confirmTitle, confirmStyle = 'danger', ...rest
  } = props;
  const { dlgClick } = useDialog();
  function getStyleClass() {
    if (variant !== 'link') {
      return '';
    }
    return disabled ? 'text-muted' : 'text-danger';
  }
  const classList = classNames([getStyleClass(), className]);
  const handleClick = confirmText ? () => dlgClick({ message: confirmText, title: confirmTitle, confirmLabel, confirmStyle }, onClick) : onClick;
  return (
    <IconButton
      variant={variant}
      icon={disabled && disabledIcon ? disabledIcon : icon}
      disabled={disabled}
      className={classList}
      onClick={handleClick}
      {...rest}
    />
  );
}

DeleteButton.propTypes = {
  icon: PropTypes.string,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  disabledIcon: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  confirmText: PropTypes.object,
  confirmTitle: PropTypes.object,
  confirmLabel: PropTypes.object,
  confirmStyle: PropTypes.string,
};

export default DeleteButton;
