/**
 *
 * PrimaryAction
 *
 */

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Button, ButtonToolbar } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { SpinnerDiv } from 'components/Spinner/index.jsx';
import cx from 'utils/cx.js';
import messages from './messages.js';

function PrimaryAction(props) {
  const {
    submitText = 'save', submitIcon, submitVariant = 'primary', valid = true, submitted, loading, onSubmit, onCancel, children, className,
  } = props;
  const finalSubmitText = submitText.id ? submitText : messages[submitText];
  const navigate = useNavigate();
  const handleCancel = useCallback(() => {
    if (typeof onCancel === 'function') {
      onCancel();
    } else {
      navigate(onCancel);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <div className={cx('d-flex justify-content-between align-items-center primary-action w-100', className)}>
      <div>{children}</div>
      <ButtonToolbar className="d-flex">
        {onCancel
          && (
            <Button variant="light" disabled={submitted} onClick={handleCancel} className="ms-4">
              <FormattedMessage {...messages.cancel} />
            </Button>
          )}
        <Button
          variant={submitVariant}
          disabled={!valid || submitted || loading}
          className="ms-4 d-flex align-items-center"
          type="submit"
          onClick={onSubmit}
          icon={submitIcon}
        >
          <SpinnerDiv show={submitted || loading}>
            <FormattedMessage {...finalSubmitText} />
          </SpinnerDiv>
        </Button>
      </ButtonToolbar>
    </div>
  );
}

PrimaryAction.propTypes = {
  onSubmit: PropTypes.func, // usually handled by form submit
  onCancel: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  submitText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  submitIcon: PropTypes.string,
  submitted: PropTypes.bool,
  loading: PropTypes.bool,
  valid: PropTypes.bool,
  children: PropTypes.node,
  submitVariant: PropTypes.string,
  className: PropTypes.string,
};

export default PrimaryAction;
