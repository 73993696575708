/**
*
* Form4
*
*/

import React, { forwardRef, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Form } from 'react-bootstrap';
import PrimaryAction from 'components/interaction/PrimaryAction/index.jsx';

import sleep from 'utils/sleep.js';
import { useIsMounted } from 'utils/useSafeState.js';
import { pick, xor } from 'lodash-es';
import useConvertSchema from './useConvertSchema.js';
import FormikGroup from './FormikGroup.jsx';
import usePreventNavigation from './usePreventNavigation.js';

const Form4 = forwardRef((props, ref) => {
  const {
    defaultValues = {}, schema, onSubmit, onCancel, onChange, submitText, submitIcon, submitVariant, children, noFocus, focusInput, navigationWarning, className, monitor = [], formGroupClass = 'mb-4', ...rest
  } = props;
  const autoFocusInput = !noFocus && (focusInput || schema.properties[0].id);
  const hasChanged = useRef(false);
  const { convertSchema } = useConvertSchema();
  const validationSchema = convertSchema(schema);
  const isMounted = useIsMounted();
  const [updateValues, setUpdateValues] = useState(null);
  const formFields = schema.properties.map((p) => p.id);
  const lastFields = useRef(formFields);

  const monitorValues = monitor.map((key) => defaultValues[key]); // Update these values in the form if the default value changes

  useEffect(() => {
    // Update changed default values if these are monitored
    const newValues = {};
    monitor.forEach((key) => {
      newValues[key] = defaultValues[key];
    });
    setUpdateValues(newValues);
  }, monitorValues); // eslint-disable-line react-hooks/exhaustive-deps

  function handleSubmitAll(values) {
    if (onSubmit) {
      onSubmit(values);
    }
    const submitProp = schema.properties.find((p) => p.onSubmit);
    if (submitProp) {
      const { id } = submitProp;
      submitProp.onSubmit(id, values[id]);
    }
  }

  usePreventNavigation(navigationWarning && hasChanged.current, onCancel);

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={defaultValues}
      onSubmit={handleSubmitAll}
    >
      {(helpers) => {
        const { handleSubmit, isSubmitting, setSubmitting, values, isValid, errors, setValues } = helpers;

        function handleChange(key, val) {
          const newValues = { ...values, [key]: val };
          hasChanged.current = true;
          if (onChange) {
            onChange(newValues, key);
          }
        }

        window.setTimeout(() => {
          // Avoid setState during rendering
          if (updateValues) {
            const newValues = { ...values, ...updateValues };
            // Update values if monitored values have changed and are different form current values
            if (Object.entries(newValues).some(([key, val]) => values[key] !== val)) {
              setValues(newValues);
              setUpdateValues(null);
            }
          }

          if (xor(formFields, lastFields.current).length > 0) {
            // Schema has changed, new or removed fields, adapt value
            lastFields.current = formFields;
            setValues(pick({ ...defaultValues, ...values }, formFields));
          }
        }, 0);

        function submitAndWait(e) {
          if (!isValid) {
            // eslint-disable-next-line no-console
            console.warn('Form is not valid', errors);
          }
          handleSubmit(e);
          sleep(1000).then(() => {
            if (isMounted()) {
              setSubmitting(false);
            }
          });
        }

        function renderField(field, options) {
          return (
            <FormikGroup
              key={field.id}
              field={field}
              helpers={helpers}
              autoFocus={field.id === autoFocusInput}
              options={options}
              onChange={handleChange}
              formGroupClass={formGroupClass}
              {...rest}
            />
          );
        }

        function render(fieldId, options) {
          const field = Array.isArray(fieldId)
            ? schema.properties.find((f) => f.id === fieldId[0])?.list.find((f) => f.id === fieldId[1])
            : schema.properties.find((f) => f.id === fieldId);
          return field && renderField(field, options);
        }

        const body = schema.properties.map((f) => renderField(f));
        const actionProps = { onCancel, submitted: isSubmitting, submitText, submitIcon, submitVariant };
        const action = <PrimaryAction {...actionProps} />;
        const getAction = (node) => <PrimaryAction {...actionProps}>{node}</PrimaryAction>;

        return (
          <Form noValidate ref={ref} onSubmit={submitAndWait} className={className}>
            {children
              ? children({ render, body, values, action, getAction })
              : (
                <>
                  {body}
                  {onSubmit && (
                    <Form.Group className={props.formGroupClass}>
                      {action}
                    </Form.Group>
                  )}
                </>
              )}
          </Form>
        );
      }}
    </Formik>
  );
});

Form4.propTypes = {
  defaultValues: PropTypes.object,
  children: PropTypes.func,
  schema: PropTypes.object,
  submitText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  submitIcon: PropTypes.string,
  submitVariant: PropTypes.string,
  onCancel: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  focusInput: PropTypes.string,
  noFocus: PropTypes.bool,
  monitor: PropTypes.array, // list of field IDs to monitor for changes, if changed, update value
  navigationWarning: PropTypes.bool,
  formGroupClass: PropTypes.string,
  className: PropTypes.string,
};

export default Form4;
