/**
 *
 * UserMenu
 *
 */

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FormattedMessage } from 'react-intl';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { pick } from 'lodash-es';
import { STUDENT_ROLE } from 'containers/User/constants.js';
import AvatarWithText from 'components/media/AvatarWithText/index.jsx';
import Icon2 from 'components/media/Icon2/index.js';
import RoleDisplay from 'components/user/RoleDisplay.js';
import SchoolDisplay from 'components/user/SchoolDisplay.jsx';
import { idValues } from 'utils/arrays.js';
import messages from './messages.js';

const Wrapper = styled(Dropdown)`
  .avatar-icon {
      /* color: ${(p) => p.theme.gray}; */
    font-size: 32px;
    width: 36px;
  }
  .marker {
    width: 30px;
    text-align: right;
    color: ${(p) => p.theme.brandSuccess}
  }
  .dropdown-toggle:after {
    color: white;
  }
`;

function UserMenu(props) {
  const { profile, currentRoleId, onRoleChange, onLogout } = props;
  const currentRole = profile?.roles[currentRoleId];
  const allMenuItems = [
    { key: 5, icon: 'ss-plus', message: messages.enterCode, as: Link, to: '/register' },
    { key: 6, icon: 'ss-userprofile', message: messages.profile, as: Link, to: '/user/profile' },
    { key: 7, icon: 'ss-settings', message: messages.settings, as: Link, to: '/settings' },
    { key: 8, icon: 'ss-help', message: messages.support, href: 'https://support.socialschools.eu/hc/nl', target: '_blank' },
    { key: 9, icon: 'ss-logout', message: messages.logout, as: 'button', onClick: onLogout },
  ];

  const menuItems = useMemo(() => {
    if (profile?.roles.list.length === 0) {
      return allMenuItems.filter((item) => ['ss-plus', 'ss-logout'].includes(item.icon));
    }
    if (!currentRole || currentRole.type === STUDENT_ROLE) {
      return allMenuItems.filter((item) => !['ss-plus', 'ss-help'].includes(item.icon));
    }
    return allMenuItems;
  }, [profile, currentRoleId, onLogout]); // eslint-disable-line react-hooks/exhaustive-deps

  function renderRole(role) {
    const roleId = role.uniqueId;
    const isCurrentRole = roleId === currentRoleId;

    return (
      <Dropdown.Item as="button" eventKey={roleId} key={roleId} disabled={isCurrentRole} onClick={isCurrentRole ? undefined : () => onRoleChange(roleId)}>
        <div className="d-flex justify-content-between align-items-center">
          <AvatarWithText
            align="center"
            size="sm"
            src={role.avatar}
          >
            <div className="small"><RoleDisplay {...role} capitalized /></div>
            <SchoolDisplay {...role} />
          </AvatarWithText>
          <div className="marker">
            {isCurrentRole && <Icon2 className="ss-checkmark text-success fs-3" />}
          </div>
        </div>
      </Dropdown.Item>
    );
  }

  const avatar = currentRole?.avatar || '';
  const roles = idValues(profile.roles);
  const hasRoles = roles && roles.length > 0;
  return (
    <Wrapper align="end" id="menu-dropdown">
      <Dropdown.Toggle variant="link" className="d-flex align-items-center text-white">
        <AvatarWithText size="sm" src={avatar}>
          <span className="d-none d-md-inline">{profile?.displayName}</span>
        </AvatarWithText>
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-0 dropdown-menu-large">
        {hasRoles && <Dropdown.Header className="bg-gray"><FormattedMessage {...messages.myRoles} /></Dropdown.Header>}
        {hasRoles && roles.map(renderRole)}
        <Dropdown.Header className="bg-gray"><FormattedMessage {...messages.myAccount} /></Dropdown.Header>
        {menuItems.map((item) => (
          <Dropdown.Item eventKey={item.key} key={item.key} {...pick(item, ['as', 'to', 'href', 'target', 'onClick'])}>
            <AvatarWithText size="sm" icon={item.icon}>
              <span>
                <FormattedMessage {...item.message} />
                {item.target && <Icon2 className="ss-external-link ms-2 fs-6" />}
              </span>
            </AvatarWithText>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Wrapper>
  );
}

UserMenu.propTypes = {
  onLogout: PropTypes.func.isRequired,
  profile: PropTypes.object,
  currentRoleId: PropTypes.number,
  onRoleChange: PropTypes.func,
};

export default UserMenu;
