/**
*
* Switch
*
*/

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Switch from 'react-switch';
import useTheme from 'containers/ThemeProvider/useTheme.js';
import { SpinnerDiv } from 'components/Spinner/index.jsx';
import cx from 'utils/cx.js';

const DEFAULT_HEIGHT = 26;
const SMALL_HEIGHT = 18;

const Label = styled.label`
  display: inline-flex;
  align-items: center;
  gap: 0.75rem;
  .switch-spinner {
    height: ${(p) => (p.size.startsWith('sm') ? SMALL_HEIGHT : DEFAULT_HEIGHT)}px;
  }
`;

function MySwitch(props) {
  const {
    value, onChange, children, disabled, before, size = '', working, optimistic, className,
  } = props;
  const [newValue, setValue] = useState(value);
  const { brandSuccess, btnDefaultBorder } = useTheme();

  useEffect(() => {
    if (!working) {
      setValue(value);
    }
  }, [value, working]);

  function handleClick() {
    if (optimistic) {
      setValue(!newValue);
    }
    onChange(!newValue);
  }
  const pending = value !== newValue;
  const enabled = !disabled && !pending;
  const height = size.startsWith('sm') ? SMALL_HEIGHT : DEFAULT_HEIGHT;
  const width = height * 2 - 4;
  const handleDiameter = height - 4;
  const dim = { width, height, handleDiameter };

  return (
    <Label className={cx(className, disabled && 'opacity-50')} size={size}>
      { before && children }
      <SpinnerDiv className="switch-spinner" show={working && !optimistic}>
        <Switch onChange={handleClick} checked={newValue} checkedIcon={false} uncheckedIcon={false} disabled={!enabled} onColor={brandSuccess} offColor={btnDefaultBorder} {...dim} />
      </SpinnerDiv>
      { !before && children }
    </Label>
  );
}

MySwitch.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.bool,
  onChange: PropTypes.func,
  children: PropTypes.node,
  before: PropTypes.bool,
  optimistic: PropTypes.bool,
  working: PropTypes.bool,
  size: PropTypes.string,
  className: PropTypes.string,
};

export default MySwitch;
