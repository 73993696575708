/**
 *
 * Spinner
 *
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import PosAbsoluteFull from 'components/layout/PosAbsoluteFull/index.js';
import cx from 'utils/cx.js';
import { rotate360 } from './constants.js';

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  @media print {
    display: none;
  }
`;

const Wrapper = styled.div`
  position: relative;
`;

const Inner = styled(PosAbsoluteFull)`
  min-height: 34px;
`;

const SpinnerIcon = styled.i.attrs((props) => ({
  className: props.icon,
}))`
  color: ${(props) => props.color || props.theme.gray};
  font-size: ${(props) => props.size};
  margin: 8px;
  animation: ${rotate360} ${(props) => props.time} linear infinite;
  z-index: 9999;
`;

function Spinner(props) {
  const { className, label, size = '32px', time = '2s', icon = 'ss-spinner1', title = 'Loading...', ...rest } = props;
  useEffect(() => {
    if (label) {
      console.log('Spinner', label); // eslint-disable-line no-console
    }
  }, [label]);
  return (
    <SpinnerContainer className={cx('spinner-container', className)}>
      <SpinnerIcon size={size} time={time} icon={icon} title={title} {...rest} />
    </SpinnerContainer>
  );
}

Spinner.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
  time: PropTypes.string,
  icon: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  label: PropTypes.string,
};

export function SpinnerDiv({ show, children, className, ...props }) {
  return (
    <Wrapper className={className}>
      {children}
      {show && <Inner><Spinner {...props} /></Inner>}
    </Wrapper>
  );
}

SpinnerDiv.propTypes = {
  show: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  icon: PropTypes.string,
};

export default Spinner;
