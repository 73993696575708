/**
*
* FormikGroup
*
*/

import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { Form } from 'react-bootstrap';
import { focusGroupInput } from 'utils/formHelpers.js';
import Icon2 from 'components/media/Icon2/index.js';
import cx from 'utils/cx.js';
import FormLabel from './FormLabel.jsx';
import FormikControl from './FormikControl.jsx';

const IconDiv = styled.div`
  flex: 0 0 2rem;
`;

const LabelDiv = styled.div`
  flex: 0 0 ${(p) => p.labelWidth};
`;

function FormikGroup(props) {
  const { field, horizontal, labelWidth = '8rem', autoFocus, formGroupClass } = props;
  const { id, label, srOnly, required, help, icon, helpAbove } = field;
  const intl = useIntl();

  const groupRef = useRef();
  useEffect(() => {
    if (autoFocus && groupRef.current) {
      focusGroupInput(groupRef.current);
    }
  }, [autoFocus]);

  const translate = (txt) => txt?.id ? intl.formatMessage(txt, txt.values) : txt;

  const formLabel = <FormLabel required={required} className={srOnly ? 'visually-hidden' : ''}>{translate(label)}</FormLabel>;
  const doHorizontal = horizontal && !srOnly;
  const groupClass = cx('form-group', 'd-flex', formGroupClass);
  return (
    <Form.Group ref={groupRef} id={`field-${id}`} controlId={id} className={groupClass}>
      {icon && (
        <IconDiv>
          {icon === true ? <i>&nbsp;</i> : <Icon2 className={`${icon} fs-1 mt-2`} />}
        </IconDiv>
      ) }
      {doHorizontal && <LabelDiv labelWidth={labelWidth} className="label-wrapper pe-4 lh-lg text-sm-end">{formLabel}</LabelDiv>}
      <div className="flex-grow-1 max-width-100">
        {!doHorizontal && formLabel}
        {helpAbove && <div className="form-text mt-0 mb-3">{translate(helpAbove)}</div>}
        <FormikControl {...props} />
        {help && <div className="form-text">{translate(help)}</div>}
      </div>
    </Form.Group>
  );
}

FormikGroup.propTypes = {
  field: PropTypes.object,
  horizontal: PropTypes.bool,
  labelWidth: PropTypes.string,
  formGroupClass: PropTypes.string,
  autoFocus: PropTypes.bool,
};

export default FormikGroup;
