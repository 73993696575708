/*
* Use custom element for FileInput
*/

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
/* eslint-disable jsx-a11y/label-has-associated-control */

const Wrapper = styled.div`
  [type="file"] {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    white-space: nowrap;
    width: 1px;
  }
  [type="file"]:focus + label {
    &:not(.btn-primary) {
      outline: 1px dotted #000;
      outline: -webkit-focus-ring-color auto 5px;
    }
    &.btn-primary {
      color: #ffffff;
      background-color: #19a5cf;
      border-color: #0e5c74;
    }
  }
  [disabled] {
    cursor: not-allowed;
  }
`;

function InputFile(props) {
  const { id, accept = '', onChange, className = 'btn', children, title, multiple, disabled } = props;
  const handleChange = (ev) => {
    onChange(Array.from(ev.target.files));
  };
  return (
    <Wrapper>
      <input type="file" id={id} value="" className="btn" accept={accept} onChange={handleChange} multiple={multiple} disabled={disabled} />
      <label htmlFor={id} className={className} title={title} disabled={disabled}>{children}</label>
    </Wrapper>
  );
}

InputFile.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  accept: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
};

export default InputFile;
