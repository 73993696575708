/**
 *
 * Avatar
 *
 */

import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { avatarSize } from 'utils/bsStyler.js';
import Img from 'components/tags/Img/index.jsx';
import userSvg from 'images/SVG/user.svg';
import userGroupSvg from 'images/SVG/usergroup.svg';
import IconButton from 'components/interaction/IconButton/index.jsx';
import { FileIconMixin } from 'utils/files.js';
import ColorBlock from '../ColorBlock/index.js';

const AvatarMixin = css`
  display: inline-block;
  width: ${avatarSize};
  height: ${avatarSize};
`;

const AvatarIcon = styled.i.attrs((props) => ({
  className: `${props.icon} avatar-icon`,
}))`
  ${AvatarMixin};
  font-size: ${avatarSize};
  line-height: 1;
  color: ${(props) => props.active ? props.theme.brandPrimary : props.theme.gray};
  text-align: center;
  border-radius: ${(props) => props.group ? props.theme.avatarRadiusGroup : props.theme.avatarRadiusUser};
  ${FileIconMixin};
`;

const AvatarImg = styled.img.attrs({ className: 'avatar-image' })`
  &&& {
    ${AvatarMixin};
    border-radius: ${(props) => props.group ? props.theme.avatarRadiusGroup : props.theme.avatarRadiusUser};
    border-width: 0;
    margin-right: ${(props) => props.space ? '10px' : 0};
    max-width: ${avatarSize};
    object-fit: cover;
  }
  &&.avatar-svg {
    object-fit: contain;
  }
  &.img-retry-spinner {
    padding: 3px;
  }
`;

const Wrapper = styled.div`
  border: none;
  display: inline-block;
  position:relative;
  height: 100%;
  & > :first-child {
    margin-right: 4px;
    margin-bottom: 4px;
  }
  & > i.avatar-icon:last-child {
    position: absolute;
    font-size: 14px;
    width: 20px;
    height: 20px;
    line-height: 1.6;
    background-color: white;
    bottom: 0;
    right: 0;
  }
`;

const EditButton = styled(IconButton)`
  &.btn {
    position: absolute;
    top: -4px;
    right: -4px;
    width: 26px;
    min-width: 26px;
    height: 26px;
    padding: 3px 6px
  }
`;

function Avatar(props) {
  const { overlay, active, onEdit, ...otherProps } = props;
  const { bsSize, src, icon, color, group } = otherProps;
  const sizeClass = bsSize ? `size-${bsSize}` : '';
  if (onEdit) {
    return (
      <Wrapper>
        <Avatar {...otherProps} bsSize={bsSize} />
        <EditButton variant="light" icon="ss-draw text-primary" onClick={onEdit} size="sm" />
      </Wrapper>
    );
  }
  if (overlay) {
    return (
      <Wrapper>
        <Avatar {...otherProps} bsSize={bsSize} />
        <AvatarIcon icon={overlay}></AvatarIcon>
      </Wrapper>
    );
  }
  if (src) {
    return <AvatarImg as={Img} className={sizeClass} alt="" {...otherProps} />;
  }
  if (icon) {
    return <AvatarIcon {...otherProps} />;
  }
  if (color) {
    return <ColorBlock {...otherProps} />;
  }
  const newProps = {
    ...otherProps,
    src: group ? userGroupSvg : userSvg,
  };
  return <AvatarImg className={`${sizeClass} avatar-svg`} alt="" {...newProps} />;
}

Avatar.propTypes = {
  overlay: PropTypes.string,
  src: PropTypes.string,
  bsSize: PropTypes.string,
  active: PropTypes.bool,
  group: PropTypes.bool,
  icon: PropTypes.string,
  onError: PropTypes.func,
  onEdit: PropTypes.func,
  color: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
};

export default Avatar;
